import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

axios.defaults.baseURL = "https://mismizany.sk/push";

/* 
import VueTimers from "vue-timers";
Vue.use(VueTimers); */

Vue.config.productionTip = false;

const moment = require("moment");
require("moment/locale/sk");

Vue.use(require("vue-moment"), {
  moment
});

window.vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
