<template>
  <div>
    <h3 v-if="!validUser" class="text-center">{{ errorMsg }}</h3>
    <v-container class="mx-auto text-center">
      <v-progress-circular
        indeterminate
        v-if="loading"
        height="38"
        size="38"
      ></v-progress-circular>
      <v-card class="mx-auto" v-if="userValid">
        <v-card-text>
          Posledná zmena:
          <b>{{ status.open == 1 ? "OTVORENÉ" : "ZATVORENÉ" }}</b> o
          {{ status.dttm }} ({{ status.user }})
        </v-card-text>
        <v-card-text class="text-center">
          <v-radio-group v-model="switch1" row class="mx-12 px-12">
            <v-radio label="OTVORENÉ" value="1"></v-radio>
            <v-spacer></v-spacer>
            <v-radio label="ZATVORENÉ" value="0"></v-radio>
          </v-radio-group>

          <!--  <v-switch
              v-model="switch1"
              :label="switch1 ? 'OTVORENÉ' : 'ZATVORENÉ'"
            ></v-switch> -->
          <v-btn :loading="saving" block color="warning" @click="setStatus"
            >OK</v-btn
          >
        </v-card-text>
      </v-card>
    </v-container>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: ["open", "status", "dttm", "validUser"],
  data: () => ({
    switch1: null,
    firstRun: true,
    userValid: false,
    userName: null,
    userNick: null,
    loading: false,
    errorMsg: null,
    saving: false
    //
  }),
  created() {
    //this.$emit("get-user");
    console.log("ADMIN created");
    this.getUser();
  },
  updated() {
    if (this.firstRun) {
      this.switch1 = this.status.open;
    }

    this.firstRun = false;
  },
  methods: {
    getUser() {
      console.log("METHOD getUser");
      this.loading = true;
      let user = this.$route.params.user;
      this.axios
        .get("https://mismizany.sk/api.php?user=" + user)
        .then(response => {
          console.log(response.data);
          if (response.data.Status == "Error") {
            this.errorMsg = "Invalid admin user";
            console.log("Invalid admin user");
          } else if (response.data[0].id == user) {
            console.log("USER is valid");
            this.userValid = true;
            this.userName = response.data[0].name;
            this.userNick = response.data[0].nick;
           

          }
        })
        .catch(error => {
          console.log(error);
          this.errorMsg = error;
        })
        .then(response => {
          console.log(response);
          this.loading = false;
           console.log("USER is valid 2");
        });
    },
    setStatus() {
      let formData = new FormData();
      this.saving = true;
      formData.append("user", this.userNick);
      formData.append("open", this.switch1);

      /*  formData.append("user", {
        user: this.userNick,
        open: this.switch1 ? 1 : 0
      });  */
      console.log(formData);
      this.axios
        .post("https://mismizany.sk/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log("SUCCESS !!", response);
          this.$emit("get-status");
        })
        .catch(error => {
          console.log("FAILURE !!", error);

          this.uploadError = true;
          this.uploadErrorMessage = error.message;
        })
        .then(response => {
          this.saving = false;
          console.log(response);
        });
      //this.$emit("get-status");
    }
  }
};
</script>

<style></style>
