<template>
  <div>
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="deep-purple accent-4"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Správa
          <v-icon>mdi-bullhorn</v-icon>
        </v-tab>

        <v-tab href="#tab-2" @change="changeTab2">
          <v-badge color="red" :content="unread" :value="unread">
            Archív
          </v-badge>
          <v-icon>mdi-clipboard-list</v-icon>
        </v-tab>

        <v-tab href="#tab-3" @change="changeTab2">
          <v-badge color="red" :content="unread" :value="unread">
            Plan
          </v-badge>
          <v-icon>mdi-clock-outline</v-icon>
        </v-tab>

      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card class="pa-4">
            <v-row justify="center">
              <v-col cols="8">
                <v-card class="pa-2">
                  <v-row>
                    <v-col cols="6">
                      <v-card flat>
                        <v-text-field
                          filled
                          hide-details
                          dense
                          v-model="title"
                          label="Nadpis"
                        ></v-text-field>
                        <v-textarea
                          hide-details
                          dense
                          filled
                          v-model="text"
                          label="Text"
                        ></v-textarea>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card flat>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="tag"
                              label="Tag"
                              hide-details
                              dense
                              
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-menu
                              v-model="menuCal"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="date"
                                  label="Kalendár"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  clearable
                                  hide-details
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                locale="sk"
                                v-model="date"
                                @input="menuCal = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              hide-details
                              dense
                              v-model="color"
                              label="Color"
                            >
                              <template v-slot:append-outer>
                                <v-btn x-small @click="dialogColor = true"
                                  >...</v-btn
                                >
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              hide-details
                              dense
                              v-model="icon"
                              label="Icon"
                            >
                              <template v-slot:append-outer>
                                <v-btn x-small @click="dialogIcon = true"
                                  >...</v-btn
                                >
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              hide-details
                              dense
                              v-model="targetUrl"
                              label="URL (web adresa)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="2">
                            <v-switch
                              style="margin-top: 4px;"
                              dense
                              hide-details
                              v-model="delayDelivery"
                              prepend-icon="mdi-clock-outline"
                            ></v-switch>
                          </v-col>
                          <v-col cols="5">
                            <v-menu
                              v-model="menuDeliveryDate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  :disabled="!delayDelivery"
                                  v-model="deliveryDate"
                                  label="Dátum doručenia:"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  clearable
                                  hide-details
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                locale="sk"
                                v-model="deliveryDate"
                                :min="new Date().toISOString()"
                                @input="menuDeliveryDate = false"
                                first-day-of-week="1"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="5">
                            <v-menu
                              ref="menuTime"
                              v-model="menuDeliveryTime"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              :return-value.sync="deliveryTime"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  :disabled="!delayDelivery"
                                  v-model="deliveryTime"
                                  label="Čas doručenia"
                                  prepend-icon="mdi-clock-outline"
                                  readonly
                                  clearable
                                  hide-details
                                  dense
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="menuDeliveryTime"
                                v-model="deliveryTime"
                                full-width
                                format="24hr"
                                
                                scrollable
                                @click:minute="
                                  $refs.menuTime.save(deliveryTime)
                                "
                              ></v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <!--  <v-row class="text-center">
                          <v-col>
                            <v-btn @click="clearNotif">Zrušiť</v-btn>
                          </v-col>
                        </v-row> -->
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col> </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card
                  class="mx-auto my-2 "
                  max-width="800"
                  elevation="5"
                  :color="color"
                  height="190"
                  style="overflow-y: auto;"
                >
                  <v-card-text>
                    <div>{{ new Date() | moment("LLLL") }}</div>
                    <div class="title text--primary">
                      <v-icon large color="black">{{ icon }}</v-icon>
                      <span v-html="htmlTitle"></span>
                    </div>

                    <div class=" subtitle-1 text--primary">
                      <span v-html="htmlText"></span>
                      <v-btn
                        x-small
                        text
                        v-if="targetUrl"
                        :href="targetUrl"
                        target="_blank"
                      >
                        <v-icon>mdi-exit-to-app</v-icon>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
                <div class="text-center pa-4">
                  <v-btn color="primary" x-large @click="dialogSend = true">
                    Poslať
                    <v-icon class="pa-3">mdi-bullhorn</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-2">
          <v-card>
            <v-row>
              <v-col cols="8">
                <v-data-table
                  :headers="headers"
                  :items="notifications"
                  :items-per-page="5"
                  class="elevation-1"
                  :search="search"
                  @click:row="test"
                  show-select
                  single-select
                  @item-selected="selectItem"
                >
                  <template v-slot:item.alert.title="{ item }">
                    <div style="max-width: 150px;">
                      {{ item.alert.title }}
                    </div>
                  </template>
                  <template v-slot:item.alert.text="{ item }">
                    <div style="max-width: 300px;">
                      {{ item.alert.text }}
                    </div>
                  </template>
                  <template v-slot:item.creationDate="{ item }">
                    {{ item.creationDate | moment("ddd D.M.YYYY HH:mm") }}
                  </template>
                  <template v-slot:item.push.payload.deliveryDate="{ item }">
                    {{
                      item.push.payload.deliveryDate
                        | moment("ddd D.M.YYYY HH:mm")
                    }}
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-icon
                      v-if="!(item.push.payload.deliveryDate > new Date())"
                      color="success"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon
                      v-if="item.push.payload.deliveryDate > new Date()"
                      color="warning"
                      >mdi-check-circle-outline</v-icon
                    >

                    <v-icon
                      v-if="item.push.payload.deliveryDate > item.creationDate"
                      color="warning"
                      >mdi-clock-outline</v-icon
                    >

                    <!-- {{ !(item.push.payload.deliveryDate > new Date()) }} -->
                  </template>
                </v-data-table>
                <!--  <v-btn @click="getNotif">get notifications </v-btn> -->
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  label="hľadať"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-card
                  class="mx-auto my-2 "
                  max-width="800"
                  elevation="5"
                  :color="color"
                  height="190"
                  style="overflow-y: auto;"
                >
                  <v-card-text>
                    <div>{{ new Date() | moment("LLLL") }}</div>
                    <div class="title text--primary">
                      <v-icon large color="black">{{ icon }}</v-icon>
                      <span v-html="htmlTitle"></span>
                    </div>

                    <div class=" subtitle-1 text--primary">
                      <span v-html="htmlText"></span>
                      <v-btn
                        x-small
                        text
                        v-if="targetUrl"
                        :href="targetUrl"
                        target="_blank"
                      >
                        <v-icon>mdi-exit-to-app</v-icon>
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
                <v-btn
                  class="ma-2 my-4"
                  :disabled="notificationID == null"
                  block
                  color="primary"
                  @click="editNotif"
                  >UPRAVIŤ</v-btn
                >
                <!--  <v-btn class="ma-2 my-4" :disabled="notificationID == null" block color="warning" @click="sendAgain">POSLAŤ znova ako novú</v-btn> -->

                <v-btn
                  class="ma-2 my-4"
                  :disabled="notificationID == null"
                  block
                  color="error"
                  @click="dialogDelete = true"
                  >ZMAZAŤ</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog permanent v-model="dialogDelete" max-width="800">
      <v-card class="pa-2">
        <v-card-title class="text-center primary white--text">
          ZMAZAŤ SPRÁVU ?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card
                class="mx-auto my-2 "
                max-width="800"
                elevation="5"
                :color="color"
                height="190"
                style="overflow-y: auto;"
              >
                <v-card-text>
                  <div>{{ new Date() | moment("LLLL") }}</div>
                  <div class="title text--primary">
                    <v-icon large color="black">{{ icon }}</v-icon>
                    <span v-html="htmlTitle"></span>
                  </div>

                  <div class=" subtitle-1 text--primary">
                    <span v-html="htmlText"></span>
                    <v-btn
                      x-small
                      text
                      v-if="targetUrl"
                      :href="targetUrl"
                      target="_blank"
                    >
                      <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-calendar
                locale="sk"
                :weekdays="weekdays"
                :events="events"
                :event-color="color"
                event-text-color="black"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loading" color="error" @click="dialogDelete = false"
            >Zrušiť</v-btn
          >
          <v-btn :loading="loading" large color="success" @click="delNotif"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog permanent v-model="dialogSend" max-width="800">
      <v-card class="pa-2">
        <v-card-title class="text-center primary white--text">
          ODOSLAŤ SPRÁVU ?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-card
                class="mx-auto my-2 "
                max-width="800"
                elevation="5"
                :color="color"
                height="190"
                style="overflow-y: auto;"
              >
                <v-card-text>
                  <div>{{ new Date() | moment("LLLL") }}</div>
                  <div class="title text--primary">
                    <v-icon large color="black">{{ icon }}</v-icon>
                    <span v-html="htmlTitle"></span>
                  </div>

                  <div class=" subtitle-1 text--primary">
                    <span v-html="htmlText"></span>
                    <v-btn
                      x-small
                      text
                      v-if="targetUrl"
                      :href="targetUrl"
                      target="_blank"
                    >
                      <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <div class="ma-2 pa-2">
                Čas doručenia
                <div class="title green--text">
                  {{ deliveryLocal }}
                </div>
              </div>
            </v-col>
            <v-col>
              <v-calendar
                locale="sk"
                :weekdays="weekdays"
                :events="events"
                :event-color="color"
                event-text-color="black"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="loading" color="error" @click="dialogSend = false"
            >Zrušiť</v-btn
          >
          <v-btn :loading="loading" large color="success" @click="sendN"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogColor" max-width="500">
      <v-card class="pa-4">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            icon
            dark
            @click="dialogColor = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col v-for="x in colors" :key="x">
              <v-btn
                :color="x"
                @click="
                  color = x;
                  dialogColor = false;
                "
              ></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="green darken-1" text @click="dialog = false">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="dialogColor = false">Agree</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogIcon" max-width="500">
      <v-card class="pa-4">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" icon dark @click="dialogIcon = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col v-for="x in icons" :key="x">
              <v-btn
                @click="
                  icon = x;
                  dialogIcon = false;
                "
              >
                <v-icon>{{ x }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <!--  <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="dialogColor = false">Agree</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="snackColor"
    >
      {{ snackText }}
      <v-btn color="white" text @click="snackbar = false">
        OK
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
/* function getDate(unix_timestamp) {
  var a = new Date(unix_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time =
    date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}
 */
export default {
  data() {
    return {
      delayDelivery: false,
      deliveryDate: null,
      deliveryTime: null,
      menuDeliveryDate: false,
      menuDeliveryTime: false,
      loading: false,
      snackbar: false,
      snackText: null,
      snackColor: "success",
      timeout: 5000,
      myNumber: 0,
      title: null,
      text: null,
      search: null,
      tag: this.$moment().format("YYYY-MM-DDTHH:mm"),
      unread: 0,
      /*  date: new Date().toISOString().substr(0, 10), */
      date: null,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      menuCal: false,
      color: null,
      icon: null,
      tab: 1,
      dialogColor: false,
      dialogIcon: false,
      dialogSend: false,
      dialogDelete: false,
      targetUrl: null,
      campaignId: null,
      notificationID: null,
      installationID: null,
      notifications: [],
      params: [],
      headers: [
        /*  { text: "ID", value: "id" }, */
        /*  { text: "Tag", value: "alert.tag" }, */
        {
          text: "Názov",
          value: "alert.title",
          width: "20%",
        },
        {
          text: "Text",
          value: "alert.text",
          width: "36%",
        },
        {
          text: "Dátum odoslania",
          value: "creationDate",
          width: "17%",
        },
        {
          text: "Dátum doručenia",
          value: "push.payload.deliveryDate",
          width: "17%",
        },
        {
          text: "Stav",
          value: "status",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    deliveryLocal() {
      const dd = this.deliveryDate + "T" + this.deliveryTime;
      if (
        this.$moment(dd).format("LLLL") == "Invalid date" ||
        !this.delayDelivery
      ) {
        return "TERAZ";
      } else {
        return this.$moment(dd).format("LLLL");
      }
    },
    events() {
      let arr = [];
      console.log("computed Events", this.date, this.color, this.title);
      if (
        this.date === null ||
        this.date === undefined ||
        this.color === null ||
        this.color === undefined ||
        this.title === null ||
        this.title === undefined
      ) {
        return arr;
      }

      if (this.$moment(this.date).format("LL") == "Invalid date") {
        return arr;
      }

      arr.push({
        name: this.title,
        start: this.date,
        color: this.color,
      });

      console.log(arr);
      return arr;

      /* if ( !(this.date === null) && (!(this.color === null)) && (!(this.title === null)))
            {
              arr.push({name: this.title, start: this.date, color: this.color})
            }

            return arr */
    },
    htmlTitle() {
      if (this.title) {
        return this.title.replace(/\n/g, "<br />");
      } else {
        return "";
      }
    },
    htmlText() {
      if (this.text) {
        return this.text.replace(/\n/g, "<br />");
      } else {
        return "";
      }
    },
    colors() {
      let result = [];
      if (this.params.length) {
        this.params.forEach((element) => {
          if (element.key == "color") {
            result.push(element.value);
          }
        });
      }

      return result;
    },
    icons() {
      let result = [];
      if (this.params.length) {
        this.params.forEach((element) => {
          if (element.key == "icon") {
            result.push(element.value);
          }
        });
      }
      return result;
    },
  },
  mounted() {
    this.getParams();
  },
  methods: {
    clearNotif() {
      this.text = "";
      this.title = "";
      this.icon = "";
      this.tag = null;
      this.color = null;
      this.date = null;
      this.targetUrl = null;
      //this.date = this.$moment().format("YYYY-MM-DD-HH-mm")
      this.url = null;
      this.deliveryDate = null
      this.deliveryTime = null
      this.delayDelivery = false
    },
    editNotif() {
      this.tab = "tab-1";
      this.tag = this.$moment().format("YYYY-MM-DDTHH:mm");
    },
    sendAgain() {},
    changeTab2() {
      this.getNotif();
    },
    changeTab3() {
      this.getScheduled();
    },

    selectItem({ item, value }) {
      console.log(item);
      console.log(value);
      if (value) {
        var replace = "<br />";
        var re = new RegExp(replace, "g");
        this.text = item.alert.text.replace(re, "\n");
        this.title = item.alert.title.replace(re, "\n");
        this.color = item.push.payload.color;
        this.icon = item.push.payload.icon;
        this.targetUrl = item.push.payload.url;
        this.date = item.push.payload.event;
        this.notificationID = item.id;
      } else {
        this.text = "";
        this.title = "";
        this.targetUrl = "";
        this.date = "";
        this.color = "";
        this.icon = "";
        this.notificationID = null;
      }
    },
    test(x) {
      //console.log("test",x)
      var replace = "<br />";
      var re = new RegExp(replace, "g");
      this.text = x.alert.text.replace(re, "\n");
      this.title = x.alert.title;
      this.color = x.push.payload.color;
      this.icon = x.push.payload.icon;
      this.notificationID = x.id;
    },

    delNotif() {
      //let api = "v1/notifications/" + this.notificationID;
      this.loading = true;
      this.axios
        .get("admin.php", {
          params: {
            id: this.notificationID,
            user: this.$store.state.user,
            token: this.$store.state.token,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.snackText = "Správa bola zmazaná.";
            this.timeout = 5000;
            this.snackColor = "success";
            this.snackbar = true;
            this.clearNotif();

            for (let i = 0; i < this.notifications.length; i++) {
              //console.log(i,)
              if (this.notifications[i].id == this.notificationID) {
                //console.log("delete ID", this.notifications[i].id)
                this.notifications.splice(i, 1);
              }
            }
            //this.notifications = [];
          }
          if (response.data.error) {
            this.snackText =
              "Správu sa nepodarilo zmazať :(    ERROR: " +
              response.data.error.code +
              " - " +
              response.data.error.message;
            this.snackColor = "error";
            this.timeout = 20000;
            this.snackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.loading = false;
          this.dialogDelete = false;
        });
    },

    getNotif() {
      this.$store.commit("setLoading", true);
      //let api = "v1/notifications?limit=10&sort=-creationDate";
      //"https://smizany.online/api.php?path=v1/notifications&limit=10&sort=-creationDate"
      //console.log("get notif")
      this.errorNet = false;
      this.offset = 10;
      this.axios
        .get("api.php", {
          params: {
            path: "v1/notifications",
            offset: 0,
            limit: 100,
            sort: "-creationDate",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.notifications = response.data.data;
          console.log(response.data.data);
          this.count = response.data.count;
          this.unread = 0;
          let index = 0;
          //this.events = [];
          //this.expand = [];
          //let ee = this.expand;
          this.notifications.forEach((x) => {
            x.index = index;
            //this.expand.push(false);
            //this.$set(this.expand, index, false);
            //x.expand = false;
            //x.exp = false;
            //this.$set(ee,index,false);
            this.$set(x, "expand", false);
            index += 1;
            if (!("push" in x)) {
              x.push = {};
            }
            if (!("payload" in x.push)) {
              x.push.payload = {};
            }
            if (!("color" in x.push.payload) || x.push.payload.color == null) {
              x.push.payload.color = "info";
            }
            if (!("icon" in x.push.payload) || x.push.payload.icon == null) {
              x.push.payload.icon = "mdi-information";
            }
            if (!("url" in x.push.payload)) {
              x.push.payload.url = null;
            }
            if (x.alert.title) {
              x.alert.title = x.alert.title.replace(/\n/g, "<br />");
            } else {
              x.alert.title = "";
            }
            if (x.alert.text) {
              x.alert.text = x.alert.text.replace(/\n/g, "<br />");
            } else {
              x.alert.text = "";
            }

            /*  if(x.push.payload.color.toLowerCase() == "info") {x.push.payload.color = "#2196f3"}
          if(x.push.payload.color.toLowerCase() == "primary") {x.push.payload.color = "#1976d2"} */
            //this.expand.push(false);
            /* this.events.push({
                          name: x.alert.title,

                          start: this.$moment(x.creationDate).format("YYYY-MM-DD"),
                          color: x.push.payload.color.toLowerCase(),
                        }); */
          });
          //this.$store.commit("setNotifications",this.notifications)
        })
        .catch((error) => {
          console.log("AXIOS Error");
          console.log(error);
          //this.errorMsg = error
          //this.errorNet = true
          this.$store.commit("setErrorMsg", error);
          this.$store.commit("setErrorNet", true);
        })
        .then((response) => {
          let res = null;
          res = response;
          response = res;
          this.$store.commit("setLoading", false);
        });
    },

    getParams() {
      this.axios
        .get("https://smizany.online/parameters.php")
        .then((response) => {
          console.log(response.data);
          this.params = response.data;
        });
    },
    sendN() {
      console.log("SEND N");
      this.loading = true;
      //let deliveryDate = "2020-05-08T12:35+02";
      //let deliveryDate = new Date("2020-05-08 15:00").getTime();
      /* let deliveryDate = new Date(
        this.deliveryDate + "T" + this.deliveryTime
      ).getTime(); */
      let deliveryDate = this.deliveryDate + " " + this.deliveryTime;
      if (this.deliveryLocal == "TERAZ") {
        deliveryDate = null;
      }
      let targetSegmentIds = "tester01";
      let params = {
        notification: {
          alert: {
            text: this.text,
            title: this.title,
            /* targetUrl: this.targetUrl, */
            tag: this.tag,
            android: {
              progress: 30,
              title: this.title,
              text: this.text,
            },
            /*  web: { timestamp: 1000, sticky: true, body: "aaa" , silent: true}  */
          },
          push: {
            payload: {
              color: this.color,
              icon: this.icon,
              url: this.targetUrl,
              event: this.date,
              deliveryDate: deliveryDate,
              targetSegmentIds: targetSegmentIds
            },
          },
        },
        deliveryDate: deliveryDate,
        /*targetSegmentIds: "@ALL", */
        targetSegmentIds: targetSegmentIds,
        /* targetSegmentIds: "@PLATFORM_ANDROID", */
        /*  targetSegmentIds: "01e092heg1tjkn02"  */
        /*  targetInstallationIds: "2c759b4b584f1db0c676a9583e191a075d91b76f" */
      };
      let wonderpush = {
        path: "v1/deliveries",
        payload: params,
        login: {
          user: this.$store.state.user,
          token: this.$store.state.token,
        },
      };

      this.axios.post("admin.php", wonderpush).then((response) => {
        console.log(response);
        console.log(response.data);
        this.loading = false;
        this.dialogSend = false;
        if (response.data.success) {
          // notification sent
          this.timeout = 5000;
          this.snackbar = true;
          this.snackText = "Správa bola odoslaná.";
          this.snackColor = "success";
          this.unread++;
          this.clearNotif();
        }
        if (response.data.error) {
          this.snackText =
            "Správu sa nepodarilo odoslať :(    ERROR: " +
            response.data.error.code +
            " - " +
            response.data.error.message;
          this.snackColor = "error";
          this.timeout = 20000;
          this.snackbar = true;
        }
      });
    },
  },
};
</script>

<style></style>
