<template>
  <div>
    <v-container class="mx-auto text-center">
      <h2>MULTIFUNKČNÉ IHRISKO</h2>
     
        <h3 class="pa-2"><a href="https://www.YouTube.com/channel/UChEyMqPlWSsP0u6BwGBqF-Q/live" target="_blank">Živé vysielanie na Youtube <v-icon color=red>mdi-youtube</v-icon></a></h3>
 
      
      
      <!--  <v-btn :loading="loading" @click="getData">GET STATUS</v-btn>
      <div>{{ open }}</div> -->

      <v-card
        @click="$emit('get-status')"
        v-if="open == 1"
        class="ma-2 mx-5 pa-9"
        elevation="15"
        tile
        x-large
        color="success"
      >
        <v-progress-circular
          indeterminate
          v-show="loading"
          height="62"
          size="62"
        ></v-progress-circular>
        <h2 v-show="!loading" class="white--text">
          <v-icon large left color="white">mdi-emoticon-happy-outline</v-icon>
          OTVORENÉ
        </h2>

        <h4 v-show="!loading" class="white--text">Podľa prevádzkových hodín</h4>
      </v-card>

      <v-card
        @click="$emit('get-status')"
        v-if="open == 0"
        class="ma-2 mx-5 pa-12"
        elevation="15"
        tile
        x-large
        color="error"
      >
        <v-progress-circular
          indeterminate
          v-show="loading"
          height="38"
          size="38"
        ></v-progress-circular>
        <h2 v-show="!loading" class="white--text">
          <v-icon large left color="white">mdi-emoticon-sad-outline</v-icon>
          ZATVORENÉ
        </h2>
      </v-card>
      <h2 class="text-uppercase warning my-1">v závislosti od počasia</h2>
      <h5>Stav načítaný {{ dttm }}</h5>

      <v-divider class="my-2"></v-divider>

      <v-card class="pa-3 text-center">
        <h2 class="my-2">Prevádzka počas prázdnin od 26. 12. 2022 do 8. 1. 2023</h2>
        
        <v-divider></v-divider>
        <h4>9:00 – 11:00</h4>
        <h4>12:00 – 14:00</h4>
        <h4>14:30 – 16:30</h4>
        <h4>17:00 – 19:00</h4>
        <v-divider></v-divider>
        <h3>VSTUPNÉ:</h3>
       
        <h4>Deti do 18 rokov: 2 €</h4>
        <h4>Dospelí: 3 €</h4>
        <v-divider></v-divider>

       <!--  <h4>Z dôvodu kapacitných obmedzení si môžete vstup rezervovať na</h4>
        <h4><a href="http://www.rezervujsi.sk/obecsmizany">www.rezervujsi.sk/obecsmizany</a></h4>
        <h4>Pozn.: Na rezerváciu je určených 30 miest, zvyšná kapacita sa bude predávať pri vstupe.</h4> -->
        
      </v-card>
      Kontakt: Ing. Ivan Hudák - 0918 590 123
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["loading", "open", "status", "dttm"],
  data: () => ({
    _loading: false,
    _status: {},
    _open: 0,
    _dttm: ""
    //
  }),

  created() {
    //this.$options.interval = setInterval(this.log, 30 * 1000);
  },
  beforeDestroy() {
    //clearInterval(this.$options.interval);
  },
  mounted() {
    //this.getData();
    console.log("emit: get-status");
    this.$emit("get-status");
  },
  methods: {
    log() {
      //console.log("Hello world");
      this.getData();
    },
    getData() {
      /*     this.loading = true;
      this.axios.get("https://obecnesluzby.sk/mi/api.php").then(response => {
        //console.log(response.data);
        this.loading = false;
        this.status = response.data[0];
        this.open = this.status.open;
        this.dttm = new Date().toLocaleString();
      }); */
    }
  }
};
</script>

<style></style>
