import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "admin",
    token: "admin",
    loading: false
  },
  mutations: {
    setLoading(state, payload)
    {
      this.state.loading = payload;
    }
  },
  actions: {},
  modules: {}
});
