<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-spacer></v-spacer>
      <h1>MI SMIŽANY</h1>
      <v-spacer></v-spacer>
    </v-app-bar>


    
    <v-content>
      <!--  <HelloWorld /> -->
      <!--  <router-view
        
      /> -->
      <router-view></router-view>
      <Admin
        v-if="$route.name == 'admin'"
        :status="status"
        :open="open"
        :dttm="dttm"
        @get-status="getStatus"
      ></Admin>
      <Status
        :loading="loading"
        :status="status"
        :open="open"
        :dttm="dttm"
        @get-status="getStatus"
      />
      
    </v-content>
  </v-app>
</template>

<script>
/* import HelloWorld from "./components/HelloWorld"; */
import Status from "./components/Status";
import Admin from "./components/Admin";

export default {
  name: "App",

  components: {
    /*  HelloWorld, */
    Status,
    Admin
  },

  data: () => ({
    //
    loading: false,
    status: {},
    open: 0,
    dttm: null,
    validUser: false
  }),
  created() {
    this.$options.interval = setInterval(this.getStatus, 30 * 1000);
  },
  mounted() {
    this.getStatus;
    console.log("MOUNTED");
    console.log(this.$route.name);
  },
  beforeDestroy() {
    clearInterval(this.$options.interval);
  },
  methods: {
    getStatus() {
      console.log("METHOD getStatus");
      this.loading = true;
      this.axios
        .get("https://mismizany.sk/api.php?action=status")
        .then(response => {
          //console.log(response.data);
          this.loading = false;
          this.status = response.data[0];
          this.open = this.status.open;
          this.dttm = new Date().toLocaleString();
        });
    }
  }
};
</script>
